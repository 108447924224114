@tailwind base;
@tailwind components;
@tailwind utilities;



::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track{
  background: #DDDDDD;
  width: 1px;
  border:2px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb{
  background-color: #942CC7;
  width: 5px;
}

::-webkit-scrollbar-thumb:hover{
  background: #942CC7;
}

#root {
  position: relative;
  background-color: #F5F5F5;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.custom-scroll::-webkit-scrollbar {
  width: 12px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}

.custom-scroll {
  overflow-y: auto;
}